//
//
//
//
//
//
//


export default {

    name: 'ScannerLayout',

    data() {
        return {
        }
    },

}
