//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Vue from "vue";
import { BCollapse, VBToggle } from 'bootstrap-vue'
Vue.directive('b-toggle', VBToggle)

export default {

  name: 'NavBar',

  components: { BCollapse },

  data() {
    return {
      collapseVisible: false,
    }
  },

  watch: {
    $route() {
      this.$nextTick(function () {
        this.collapseVisible = false;
      });
    }
  },

  mounted() {
    this.collapseVisible = false;
  },
}
