
export default function ({ $config, $logger, app, store, $bus }, inject) {

    //const TrackingManager = new Tracking(app.router, store, $bus)
    //TrackingManager.start().catch(console.error)

    install(app.router, store, $bus).catch((e) => $logger.captureException(e) )
}


async function install(router, store, bus) {

    let doTrack

    bus.$on('headChanged', metaInfo => {

        const status = store.state.tracking.status

        if (doTrack !== false && status === 'ready') {

            bus.$emit('track/run', doTrack)
            doTrack = false
        }

    })

    router.afterEach((to, from) => {

        const payload = { to, from }

        // track initial page view
        if (doTrack === undefined) {
            handleInitialPageview(store, bus, payload)
            doTrack = false
        } else {
            doTrack = payload
        }
    })

}

function handleInitialPageview(store, bus, payload) {

/*  bus.$emit('track/run', payload)
  store.commit('tracking/trackInitialPageView', true)
  store.commit('tracking/payloadInitialPageView', payload)*/

    if(store.state.ccm.widgetLoaded === true) {
        bus.$emit('track/run', payload)
        store.commit('tracking/trackInitialPageView', true)
        store.commit('tracking/payloadInitialPageView', payload)
    } else {

        bus.$on('ccm/widgetLoaded', () => {
            bus.$emit('track/run', payload)
            store.commit('tracking/trackInitialPageView', true)
            store.commit('tracking/payloadInitialPageView', payload)
        })

    }

}
