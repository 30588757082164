
export default function ({app, store, $logger}, inject) {

  install(app.router, store).catch((e) => $logger.captureException(e) )

}

async function install(router, store) {

  router.beforeEach(async (to, from, next) => {
    await refreshOutdated(store)
    next()
  })

}

async function refreshOutdated(store) {

  const outdated = store.getters["events/isOutdated"](new Date())

  if(outdated === false)
  {
    return
  }

  await store.dispatch('events/GET_EVENTS_CACHE')

}
