import { render, staticRenderFns } from "./FooterBar.vue?vue&type=template&id=62796634&"
import script from "./FooterBar.vue?vue&type=script&lang=js&"
export * from "./FooterBar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LocationIcon: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/svgs/LocationIcon.vue').default,FacebookIcon: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/svgs/FacebookIcon.vue').default,InstagramIcon: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/svgs/InstagramIcon.vue').default,YouTubeIcon: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/svgs/YouTubeIcon.vue').default,TwitchIcon: require('/var/www/vhosts/huckebein.dev-factory.de/httpdocs/components/svgs/TwitchIcon.vue').default})
