//
//
//
//

import fill from "./utilities/fill";
export default {
  name: 'instagram-icon',
  mixins: [fill]
}
