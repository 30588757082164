import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c5fd6544 = () => interopDefault(import('../pages/anfahrt.vue' /* webpackChunkName: "pages/anfahrt" */))
const _22c159ff = () => interopDefault(import('../pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _35d18626 = () => interopDefault(import('../pages/events.vue' /* webpackChunkName: "pages/events" */))
const _67d12012 = () => interopDefault(import('../pages/firmenfeiern.vue' /* webpackChunkName: "pages/firmenfeiern" */))
const _66dd4192 = () => interopDefault(import('../pages/galerie.vue' /* webpackChunkName: "pages/galerie" */))
const _725f2f5a = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _1e21535a = () => interopDefault(import('../pages/scanner.vue' /* webpackChunkName: "pages/scanner" */))
const _ad7d08f0 = () => interopDefault(import('../pages/ueber-uns.vue' /* webpackChunkName: "pages/ueber-uns" */))
const _f3371282 = () => interopDefault(import('../pages/event/checkin/_id.vue' /* webpackChunkName: "pages/event/checkin/_id" */))
const _8e098930 = () => interopDefault(import('../pages/event/_id/galerie/_slug.vue' /* webpackChunkName: "pages/event/_id/galerie/_slug" */))
const _2a4fe3c8 = () => interopDefault(import('../pages/event/_id/_slug.vue' /* webpackChunkName: "pages/event/_id/_slug" */))
const _722b9d2e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'router-link-active',
  linkExactActiveClass: 'router-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/anfahrt",
    component: _c5fd6544,
    name: "anfahrt"
  }, {
    path: "/datenschutz",
    component: _22c159ff,
    name: "datenschutz"
  }, {
    path: "/events",
    component: _35d18626,
    name: "events"
  }, {
    path: "/firmenfeiern",
    component: _67d12012,
    name: "firmenfeiern"
  }, {
    path: "/galerie",
    component: _66dd4192,
    name: "galerie"
  }, {
    path: "/impressum",
    component: _725f2f5a,
    name: "impressum"
  }, {
    path: "/scanner",
    component: _1e21535a,
    name: "scanner"
  }, {
    path: "/ueber-uns",
    component: _ad7d08f0,
    name: "ueber-uns"
  }, {
    path: "/event/checkin/:id?",
    component: _f3371282,
    props: true,
    name: "event.checkin"
  }, {
    path: "/event/:id?/galerie/:slug?",
    component: _8e098930,
    props: true,
    name: "event.gallery"
  }, {
    path: "/event/:id?/:slug?",
    component: _2a4fe3c8,
    props: true,
    name: "event.detail"
  }, {
    path: "/",
    component: _722b9d2e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
