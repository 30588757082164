export const FooterBar = () => import('../../components/FooterBar.vue' /* webpackChunkName: "components/footer-bar" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const LoadingPage = () => import('../../components/LoadingPage.vue' /* webpackChunkName: "components/loading-page" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Paypal = () => import('../../components/Paypal.vue' /* webpackChunkName: "components/paypal" */).then(c => wrapFunctional(c.default || c))
export const UnleashFeature = () => import('../../components/UnleashFeature.vue' /* webpackChunkName: "components/unleash-feature" */).then(c => wrapFunctional(c.default || c))
export const AdminScanner = () => import('../../components/admin/AdminScanner.vue' /* webpackChunkName: "components/admin-scanner" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/carousel/Carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/contact/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const ContactUs = () => import('../../components/contact/ContactUs.vue' /* webpackChunkName: "components/contact-us" */).then(c => wrapFunctional(c.default || c))
export const UpcomingEvents = () => import('../../components/events/UpcomingEvents.vue' /* webpackChunkName: "components/upcoming-events" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../components/gallery/Gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const ArrowRightIcon = () => import('../../components/svgs/ArrowRightIcon.vue' /* webpackChunkName: "components/arrow-right-icon" */).then(c => wrapFunctional(c.default || c))
export const AwardsIcon = () => import('../../components/svgs/AwardsIcon.vue' /* webpackChunkName: "components/awards-icon" */).then(c => wrapFunctional(c.default || c))
export const BusinessPlanIcon = () => import('../../components/svgs/BusinessPlanIcon.vue' /* webpackChunkName: "components/business-plan-icon" */).then(c => wrapFunctional(c.default || c))
export const CheckIcon = () => import('../../components/svgs/CheckIcon.vue' /* webpackChunkName: "components/check-icon" */).then(c => wrapFunctional(c.default || c))
export const EditIcon = () => import('../../components/svgs/EditIcon.vue' /* webpackChunkName: "components/edit-icon" */).then(c => wrapFunctional(c.default || c))
export const EmailIcon = () => import('../../components/svgs/EmailIcon.vue' /* webpackChunkName: "components/email-icon" */).then(c => wrapFunctional(c.default || c))
export const EnterIcon = () => import('../../components/svgs/EnterIcon.vue' /* webpackChunkName: "components/enter-icon" */).then(c => wrapFunctional(c.default || c))
export const FacebookIcon = () => import('../../components/svgs/FacebookIcon.vue' /* webpackChunkName: "components/facebook-icon" */).then(c => wrapFunctional(c.default || c))
export const FirmenfeiernIcon = () => import('../../components/svgs/FirmenfeiernIcon.vue' /* webpackChunkName: "components/firmenfeiern-icon" */).then(c => wrapFunctional(c.default || c))
export const InstagramIcon = () => import('../../components/svgs/InstagramIcon.vue' /* webpackChunkName: "components/instagram-icon" */).then(c => wrapFunctional(c.default || c))
export const LiveIcon = () => import('../../components/svgs/LiveIcon.vue' /* webpackChunkName: "components/live-icon" */).then(c => wrapFunctional(c.default || c))
export const LocationIcon = () => import('../../components/svgs/LocationIcon.vue' /* webpackChunkName: "components/location-icon" */).then(c => wrapFunctional(c.default || c))
export const MusicalIcon = () => import('../../components/svgs/MusicalIcon.vue' /* webpackChunkName: "components/musical-icon" */).then(c => wrapFunctional(c.default || c))
export const PartyIcon = () => import('../../components/svgs/PartyIcon.vue' /* webpackChunkName: "components/party-icon" */).then(c => wrapFunctional(c.default || c))
export const TwitchIcon = () => import('../../components/svgs/TwitchIcon.vue' /* webpackChunkName: "components/twitch-icon" */).then(c => wrapFunctional(c.default || c))
export const WarningIcon = () => import('../../components/svgs/WarningIcon.vue' /* webpackChunkName: "components/warning-icon" */).then(c => wrapFunctional(c.default || c))
export const WhatsappIcon = () => import('../../components/svgs/WhatsappIcon.vue' /* webpackChunkName: "components/whatsapp-icon" */).then(c => wrapFunctional(c.default || c))
export const YouTubeIcon = () => import('../../components/svgs/YouTubeIcon.vue' /* webpackChunkName: "components/you-tube-icon" */).then(c => wrapFunctional(c.default || c))
export const Scribbles = () => import('../../components/svgs/scribbles.vue' /* webpackChunkName: "components/scribbles" */).then(c => wrapFunctional(c.default || c))
export const TicketsBuyModal = () => import('../../components/tickets/TicketsBuyModal.vue' /* webpackChunkName: "components/tickets-buy-modal" */).then(c => wrapFunctional(c.default || c))
export const Tour = () => import('../../components/tour/Tour.vue' /* webpackChunkName: "components/tour" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/base/buttons/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonLink = () => import('../../components/base/buttons/BaseButtonLink.vue' /* webpackChunkName: "components/base-button-link" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonSpinner = () => import('../../components/base/buttons/BaseButtonSpinner.vue' /* webpackChunkName: "components/base-button-spinner" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/base/card/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const CardContainer = () => import('../../components/base/card/CardContainer.vue' /* webpackChunkName: "components/card-container" */).then(c => wrapFunctional(c.default || c))
export const CardPlaceholder = () => import('../../components/base/card/CardPlaceholder.vue' /* webpackChunkName: "components/card-placeholder" */).then(c => wrapFunctional(c.default || c))
export const HeaderImage = () => import('../../components/base/header/HeaderImage.vue' /* webpackChunkName: "components/header-image" */).then(c => wrapFunctional(c.default || c))
export const Fill = () => import('../../components/svgs/utilities/fill.js' /* webpackChunkName: "components/fill" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
