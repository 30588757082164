export const state = () => ({
    matomo: false,
    matomoScriptUrl: false,
    widgetLoaded: false,
    consent: false,
    updated: false,
})

export const mutations = {
    matomo (state, consented) {
        state.matomo = consented
    },
    matomoScriptUrl (state, url) {
        state.matomoScriptUrl = url
    },
    widgetLoaded (state, loaded) {
        state.widgetLoaded = loaded
    },
    consent (state, consent) {
        state.consent = consent
    },
    updated (state, updated) {
      state.updated = updated
    }
}
