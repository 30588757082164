//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    name: 'DefaultLayout',

    data() {
        return {
        }
    },

  watch: {

    matomo(val) {
      this.$matomo.setConsent(val)
    },

  },

  head: {
    afterNavigation(metaInfo) {
      //console.log('afterNavigation')
      $nuxt.$bus.$emit('afterNavigation', metaInfo)
    },
    changed(newInfo, addedTags, removedTags) {
      //console.log('headChanged layout', newInfo, addedTags, removedTags)
      $nuxt.$bus.$emit('headChanged', newInfo)
    }
  },

  methods: {

  },

  computed: {

    matomo() {
      return this.$store.state.ccm.matomo
    },
/*    isCrawler() {
      return this.$store.state.isCrawler
    },*/
  },

}
