//
//
//
//
//
//

import fill from "./utilities/fill";
export default {
	name: "location-icon",
	mixins: [fill]
};
