import {differenceInMinutes} from 'date-fns'

export const state = () => ({
  events: null,
  timestamp: null,
  last_gallery: null,
  gallery_index: null,
  features: null,
})

export const getters = {

  upcoming: (state) => {

    if (state.events === null) {
      return null
    }

    return state.events.filter(event => {
      if (event.is_past === false) {
        return event;
      }
    })
  },

  past: (state) => {

    if (state.events === null) {
      return null
    }

    return state.events.filter(event => {
      if (event.is_past === true) {
        return event;
      }
    })
  },

  eventById: (state) => (id) => {
    return state.events.find(event => event.id === id)
  },

  isOutdated: (state) => (dt) => {

    const diffMinuntes = differenceInMinutes(dt, state.timestamp)
    return diffMinuntes >= 15

  },

  featureEnabled: (state) => (feature) => {
    if (state.features === null) {
      return false
    }

    if (!state.features[feature]) {
      return false
    }

    return state.features[feature]

  }

}

export const mutations = {

  SET_EVENTS_TIMESTAMP: (state, timestamp) => (state.timestamp = timestamp),
  SET_EVENTS: (state, events) => (state.events = events),
  SET_LAST_GALLERY: (state, gallery) => (state.last_gallery = gallery),
  SET_GALLERY_INDEX: (state, index) => (state.gallery_index = index),
  SET_FEATURES: (state, features) => (state.features = features),

}


export const actions = {

  GET_EVENTS({commit, state}) {

    return new Promise((resolve, reject) => {

      this.$axios.$get(this.$ziggy.route('api.events'))
        .then((response) => {
          commit('SET_LAST_GALLERY', response.gallery)
          commit('SET_FEATURES', response.features)
          commit('SET_EVENTS', response.data)
          commit('SET_EVENTS_TIMESTAMP', new Date())
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })

    })
  },

  GET_EVENTS_CACHE({commit, dispatch}) {

    return new Promise((resolve, reject) => {

      this.$xhrCache.getResourceById('events')
        .then((response) => {

          if (response.error && response.error === true) {
            //console.log('error: ' + response.error)
            dispatch('GET_EVENTS').then((res) => {
              resolve(res)
            })
              .catch((error) => {
                reject(error)
              })
          } else {
            commit('SET_LAST_GALLERY', response.gallery)
            commit('SET_FEATURES', response.features)
            commit('SET_EVENTS', response.data)
            commit('SET_EVENTS_TIMESTAMP', new Date())
            resolve(response)
          }

        })
        .catch((error) => {
          reject(error)
        })

    })
  },

  GET_GALLERY({commit, state}, eventId) {

    return new Promise((resolve, reject) => {

      this.$axios.$get(this.$ziggy.route('api.event.gallery', {event: eventId}))
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })

    })
  },

  GET_GALLERY_INDEX({commit, state}) {
    const index = state.gallery_index
    commit('SET_GALLERY_INDEX', null)
    return index
  },


}
