import Vue from 'vue'
import {ZiggyVue} from "ziggy-js/dist/vue";
import Ziggy from '~/assets/js/ziggy/routes.json'
import route from 'ziggy-js'

Vue.use(ZiggyVue, Ziggy)

const ziggy = {
  route: (name, params, absolute, config = Ziggy) => route(name, params, absolute, config),
}

export default (context, inject) => {
  inject('ziggy', ziggy)
}
