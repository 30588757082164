//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fill from "./utilities/fill";
export default {
  name: "youtube-icon",
  mixins: [fill]
};
