
export const state = () => ({
  isCrawler: false,
})

export const getters = {

}


export const mutations = {

  setIsCrawler (state, value) {
    state.isCrawler = value
  },

}


export const actions = {

  async nuxtServerInit({commit, dispatch}, { req }) {
    await dispatch('events/GET_EVENTS_CACHE')
  }
}
